import React from 'react'
import { Helmet } from 'react-helmet'

import Header from './header'
import Footer from './footer'

const FullLayout = ({ children, bodyClasses = '' }) => (
  <>
    <Helmet bodyAttributes={{ class: `antialiased bg-black text-white max-w-full overflow-x-hidden ${bodyClasses}` }} />

    <div className="min-h-screen">
      <Header />

      <main className="mt-32 mx-auto py-8 md:py-16">
        {children}
      </main>

      <Footer />
    </div>
  </>
)

export default FullLayout
