import React from 'react'
import { graphql, Link } from 'gatsby'
import { StaticImage, GatsbyImage, getImage } from 'gatsby-plugin-image'
import { HiOutlineArrowNarrowRight } from '@react-icons/all-files/hi/HiOutlineArrowNarrowRight'
import { GoLinkExternal } from '@react-icons/all-files/go/GoLinkExternal'
import { InlineWidget } from "react-calendly";

import FullLayout from '../components/layout/fulllayout'
import FadeInSection from '../components/fadeinsection'
import Button from '../components/button'
import ContactForm from '../components/contactform'
import ResponsiveHeader from '../components/responsiveheader'
import SEO from '../components/seo'
import content from "../content/index.json"

const IndexPage = ({ data }) => {
  const pinnedPost = data.blogs.edges.find(({ node }) => node.fields.slug == "/blog/girls-get-loud/").node

  return (
    <FullLayout>
      <SEO title="Top Dog Studios" titleExtension={false} />

      <div className="grid w-full">
        <StaticImage
          src="../images/background1.jpg"
          alt=""
          className="-mt-48 -z-20 transform rotate-180 opacity-25 max-h-two-thirds-screen"
          style={{ gridArea: "1/1" }}
          layout="fullWidth"
          quality={90}
          loading="eager"
        />

        <div className="grid relative" style={{ gridArea: "1/1" }}>
          <div className="contained pt-16 pb-36">
            <FadeInSection delay="0.1s">
              <ResponsiveHeader className="font-black mb-8" size={3}>{content.title}</ResponsiveHeader>
              <p className="text-xl lg:text-2xl mb-8">We're a collective of <strong>creatives</strong>, <strong>strategists</strong>, and <strong>technical experts</strong> building in the wonderful world of <span className="text-gradient-100 font-bold">Web3</span>.</p>
              <p className="text-xl lg:text-2xl mb-12">We help <strong>brands</strong> create unique <strong>experiences</strong> across the metaverse.</p>

              <Button action="#contact" theme="colorful">
                <span className="inline-block text-xl py-2 ml-4">Work With Us <HiOutlineArrowNarrowRight className="inline mr-4" size="1.5em" /></span>
              </Button>
            </FadeInSection>
          </div>
        </div>
      </div>

      <div className="grid w-full -mt-24" style={{ clipPath: "polygon(0 5%, 100% 0%, 100% 100%, 0% 100%)" }}>
        <StaticImage
          src="../images/background2.jpg"
          alt=""
          className="-z-20 max-h-half-screen"
          style={{ gridArea: "1/1" }}
          layout="fullWidth"
          quality={90}
          loading="eager"
        />

        <div className="grid relative my-auto" style={{ gridArea: "1/1" }}>
          <div className="contained py-16">
            <FadeInSection delay="0.25s">
              <p className="font-black text-2xl md:text-5xl lg:text-6xl xl:text-7xl tracking-tight" size={4}>
                <span className="invisible">+ </span>your beautiful art<br />
                + our magical tech<br />
                = one incredible experience
              </p>
            </FadeInSection>
          </div>
        </div>
      </div>

      <div className="bg-gray-100 pt-8 pb-12 px-8 md:px-16 lg:px-32 shadow-inner">
        <FadeInSection delay="0.25s">
          <div className="contained text-center">
            <p className="text-black font-bold uppercase text-sm mb-8">In the media</p>

            <div className="grid mx-auto gap-6 grid-cols-1 md:grid-cols-3">
              <Link to="https://thedefiant.io/there-are-nfts-buried-in-the-arctic-we-went-to-find-out-why/" target="_blank">
                <StaticImage src="../images/thedefiant.png" alt="The Defiant" className="mx-auto" placeholder="blurred" layout="fixed" width={150} />
              </Link>

              <Link to="https://finance.yahoo.com/news/artists-helping-fight-gender-inequality-110000856.html" target="_blank">
                <StaticImage src="../images/yahoo.png" alt="The Defiant" className="mx-auto" placeholder="blurred" layout="fixed" width={140} />
              </Link>

              <Link to="https://www.eureporter.co/world/2022/03/18/digital-art-and-nfts-deposited-for-eternity-in-the-arctic-world-archive/" target="_blank">
                <StaticImage src="../images/eureporter.png" alt="The Defiant" className="mx-auto" placeholder="blurred" layout="fixed" width={170} />
              </Link>
            </div>
          </div>
        </FadeInSection>
      </div>

      <div className="bg-black py-48 px-8 md:px-16 lg:px-32">
          <FadeInSection delay="0.25s">
              <div className="container flex flex-col lg:flex-row mx-auto">
                  <div className="w-full lg:w-1/3 text-lg text-justify leading-8 mr-0 mb-10 lg:mb-0 lg:mr-10">
                      <ResponsiveHeader size={2} className="uppercase font-black flex-grow mb-6 text-left">BORROW OUR BRAINS</ResponsiveHeader>
                      <p className="mb-6">We have successfully bootstrapped a multi million-dollar brand and global NFT community made up of more than 3,200 people. We have one of the most innovative teams and the most energetic and passionate communities in this space.</p>
                      <p className="mb-6">So we understand that launching an NFT project can be a significant challenge as there are many moving parts to consider—all of which must work together cohesively to ensure a successful launch.</p>
                      <p>And that's why we created the Top Dog LaunchPad—to ensure your launch is as smooth as possible.</p>
                  </div>

                  <div className="w-full text-right lg:w-2/3 ml-0 mt-10 lg:mt-0 lg:ml-10 text-xl md:text-2xl lg:text-3xl xl:text-4xl font-black">
                      <p className="mb-4">Art generation</p>
                      <p className="mb-4">Blockchain development</p>
                      <p className="mb-4">Collaborations &amp; partnerships</p>
                      <p className="mb-4">Drop &amp; pricing strategy</p>
                      <p className="mb-4">Launch execution</p>
                      <p className="mb-4">Reveal management</p>
                      <p className="mb-4">Smart contract audits</p>
                      <p className="mb-4">Treasury and governance advice</p>
                      <p className="mb-4">Web3 integration</p>
                      <p>Website development</p>
                  </div>
              </div>
          </FadeInSection>
      </div>

      {/* <div className="bg-white py-48 px-8 md:px-16 lg:px-32 shadow-inner">
        <FadeInSection delay="0.25s">
            <div className="container flex flex-col lg:flex-row mx-auto">
              <div className="w-full lg:w-1/2 mr-0 mb-10 lg:mb-0 lg:mr-6">
                  <p className="text-3xl md:text-4xl lg:text-5xl lg:leading-tight text-gray-900 font-bold mb-6">
                      <Link to="/team/" style={{ textDecorationThickness: "8px" }}>We</Link> know what it takes to build a successful NFT community from scratch.
                  </p>
                  <p className="text-lg text-gray-800 mb-10">
                      We're not here to talk the talk—we've walked the walk.
                      We've experienced first-hand the challenges of launching a successful NFT project.
                      So we're here to help you launch yours.
                  </p>
                  <Button action="#contact" size="xl" theme="blue" className="px-3 md:px-6 md:py-2 shadow-xl">
                      Let's collaborate <HiOutlineArrowNarrowRight className="inline" size="1.75em" />
                  </Button>
              </div>

              <div className="w-full lg:w-1/2 text-black ml-0 mt-10 lg:mt-0 lg:ml-6">
                <div className="relative rounded-lg shadow-2xl">
                  <StaticImage
                      src="../images/the-explorer.jpg"
                      className="rounded-lg object-cover w-full h-96"
                      alt="THE EXPLORER"
                  />

                  <div className="absolute bottom-0 p-6 w-full">
                      <p className="text-xl font-bold text-white">THE EXPLORER</p>
                      <p className="text-base text-gray-300">By Krsitian Levin</p>
                  </div>
                </div>
              </div>
            </div>
        </FadeInSection>
      </div> */}

      <div className="bg-white py-48 px-8 md:px-16 lg:px-32"
        style={{ clipPath: "polygon(0 0, 100% 10%, 100% 100%, 0% 100%)" }}
      >
        <FadeInSection delay="0.25s">
            <div className="container flex flex-col md:flex-row mx-auto text-black">
                <div>
                    <ResponsiveHeader size={2} className="uppercase font-black flex-grow mb-6 text-left">OUR SUCCESS</ResponsiveHeader>
                    <p className="text-lg text-gray-800">
                        Don't just take our word for it. Here's a sample of projects and brands we've helped launch.
                    </p>
                </div>
                
                <div className="grid gap-4 grid-cols-1 md:grid-cols-2 xl:grid-cols-3 ml-0 md:ml-12 mt-6 md:mt-0">
                    {content.projects.map(project =>
                        <div key={project.name} className="flex flex-col bg-black text-white p-6 rounded">
                            <p className="text-xl font-bold">
                                <a href={project.website} target="_blank" className="text-white">
                                    <span>{project.name}</span>
                                    <GoLinkExternal className="ml-2 inline-block" size="1.25em" />
                                </a>
                            </p>
                            <p className="flex-1 text-sm text-justify text-gray-300 my-6 leading-6">{project.description}</p>

                            <div className="flex flex-row border-t py-4">
                                <p className="flex flex-col flex-auto text-left">
                                    <span className="text-gray-500 uppercase text-xs">SUPPLY</span>
                                    <span className="font-black text-lg md:text-xl">{project.supply}</span>
                                </p>
                                <p className="flex flex-col flex-auto text-right">
                                    <span className="text-gray-500 uppercase text-xs">REVENUE</span>
                                    <span className="font-black text-lg md:text-xl">{project.revenue}</span>
                                </p>
                            </div>
                        </div>
                    )}

                    <div className="flex flex-col p-6 border border-black rounded">
                        <p className="text-xl font-bold">YOUR PROJECT HERE</p>
                        <p className="flex-1 text-sm text-justify text-gray-800 my-6">
                            Let us help bring your brand and story to life across the metaverse.
                        </p>

                        <div className="flex flex-row border-t py-4">
                            <p className="flex flex-col flex-auto text-left">
                                <span className="text-gray-500 uppercase text-xs">SUPPLY</span>
                                <span className="font-black text-lg md:text-xl">??,???</span>
                            </p>
                            <p className="flex flex-col flex-auto text-right">
                                <span className="text-gray-500 uppercase text-xs">REVENUE</span>
                                <span className="font-black text-lg md:text-xl">$?,???,???</span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </FadeInSection>
      </div>


      <div className="grid w-full" style={{ clipPath: "polygon(0 0%, 100% 0%, 100% 90%, 0% 100%)" }}>
        <GatsbyImage
          image={getImage(pinnedPost.frontmatter.image)}
          alt=""
          className="-z-20 max-h-half-screen opacity-50"
          style={{ gridArea: "1/1" }}
          layout="fullWidth"
          quality={90}
        />

        <div className="grid relative mt-12" style={{ gridArea: "1/1" }}>
          <div className="contained py-16">
              <p className="mb-1"><span className="text-sm uppercase font-bold py-1 px-2 bg-yellow-500 rounded-md">FROM THE BLOG</span></p>
              <ResponsiveHeader className="uppercase" size={4}>{pinnedPost.frontmatter.title}</ResponsiveHeader>
              <p className="font-medium text-lg lg:text-xl">{pinnedPost.frontmatter.summary}</p>
              <p className="text-gray-400 text-sm">Published {pinnedPost.frontmatter.date} by {pinnedPost.frontmatter.author}</p>
              <p className="text-gray-200 my-6">{pinnedPost.excerpt}</p>
              <p><Button action={pinnedPost.fields.slug} theme="blue">Continue reading <HiOutlineArrowNarrowRight className="inline" size="15pt" /></Button></p>
          </div>
        </div>
      </div>

      <div className="contained py-16" id="contact">
        <ResponsiveHeader size={2} className="uppercase font-black">Let's connect</ResponsiveHeader>
        <p className="text-gray-300">
          Drop us an e-mail at <Link to="mailto:hello@topdogstudios.io">hello@topdogstudios.io</Link> or use the calendar below to book an intro meeting with the team.
        </p>

        <InlineWidget
          url="https://calendly.com/top-dog-studios/10m"
          styles={{ height: "660px" }}
          pageSettings={{ backgroundColor: "000000", textColor: "ffffff", hideLandingPageDetails: true, hideGdprBanner: true }}
        />
      </div>
    </FullLayout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query {
    blogs: allMarkdownRemark(
      filter: {
        frontmatter: { visible: { ne: false } }
        fields: { collection: { eq: "blog" } }
      }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          excerpt(pruneLength: 200)
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM D, YYYY")
            title
            author
            pinned
            summary
            image {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, quality: 90)
              }
            }
          }
        }
      }
    }
  }
`